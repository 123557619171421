const countryCodes = [
  'af',
  'af-NA',
  'af-ZA',
  'ak',
  'ak-GH',
  'am',
  'am-ET',
  'ar',
  'ar-AE',
  'ar-BH',
  'ar-DJ',
  'ar-DZ',
  'ar-EG',
  'ar-EH',
  'ar-ER',
  'ar-IL',
  'ar-IQ',
  'ar-JO',
  'ar-KM',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-MR',
  'ar-OM',
  'ar-PS',
  'ar-QA',
  'ar-SA',
  'ar-SD',
  'ar-SO',
  'ar-SS',
  'ar-SY',
  'ar-TD',
  'ar-TN',
  'ar-YE',
  'as',
  'as-IN',
  'az',
  'az-AZ',
  'az-Cyrl',
  'az-Cyrl-AZ',
  'az-Latn',
  'az-Latn-AZ',
  'be',
  'be-BY',
  'bg',
  'bg-BG',
  'bm',
  'bm-Latn',
  'bm-Latn-ML',
  'bm-ML',
  'bn',
  'bn-BD',
  'bn-IN',
  'bo',
  'bo-CN',
  'bo-IN',
  'br',
  'br-FR',
  'bs',
  'bs-BA',
  'bs-Cyrl',
  'bs-Cyrl-BA',
  'bs-Latn',
  'bs-Latn-BA',
  'ca',
  'ca-AD',
  'ca-ES',
  'ca-FR',
  'ca-IT',
  'ce',
  'ce-RU',
  'cs',
  'cs-CZ',
  'cy',
  'cy-GB',
  'da',
  'da-DK',
  'da-GL',
  'de',
  'de-AT',
  'de-BE',
  'de-CH',
  'de-DE',
  'de-IT',
  'de-LI',
  'de-LU',
  'dz',
  'dz-BT',
  'ee',
  'ee-GH',
  'ee-TG',
  'el',
  'el-CY',
  'el-GR',
  'en',
  'en-AE',
  'en-AG',
  'en-AI',
  'en-AS',
  'en-AT',
  'en-AU',
  'en-BB',
  'en-BE',
  'en-BI',
  'en-BM',
  'en-BS',
  'en-BW',
  'en-BZ',
  'en-CA',
  'en-CC',
  'en-CH',
  'en-CK',
  'en-CM',
  'en-CX',
  'en-CY',
  'en-DE',
  'en-DG',
  'en-DK',
  'en-DM',
  'en-ER',
  'en-FI',
  'en-FJ',
  'en-FK',
  'en-FM',
  'en-GB',
  'en-GD',
  'en-GG',
  'en-GH',
  'en-GI',
  'en-GM',
  'en-GU',
  'en-GY',
  'en-HK',
  'en-IE',
  'en-IL',
  'en-IM',
  'en-IN',
  'en-IO',
  'en-JE',
  'en-JM',
  'en-KE',
  'en-KI',
  'en-KN',
  'en-KY',
  'en-LC',
  'en-LR',
  'en-LS',
  'en-MG',
  'en-MH',
  'en-MO',
  'en-MP',
  'en-MS',
  'en-MT',
  'en-MU',
  'en-MW',
  'en-MY',
  'en-NA',
  'en-NF',
  'en-NG',
  'en-NL',
  'en-NR',
  'en-NU',
  'en-NZ',
  'en-PG',
  'en-PH',
  'en-PK',
  'en-PN',
  'en-PR',
  'en-PW',
  'en-RW',
  'en-SB',
  'en-SC',
  'en-SD',
  'en-SE',
  'en-SG',
  'en-SH',
  'en-SI',
  'en-SL',
  'en-SS',
  'en-SX',
  'en-SZ',
  'en-TC',
  'en-TK',
  'en-TO',
  'en-TT',
  'en-TV',
  'en-TZ',
  'en-UG',
  'en-UM',
  'en-US',
  'en-VC',
  'en-VG',
  'en-VI',
  'en-VU',
  'en-WS',
  'en-ZA',
  'en-ZM',
  'en-ZW',
  'eo',
  'es',
  'es-AR',
  'es-BO',
  'es-BR',
  'es-BZ',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-CU',
  'es-DO',
  'es-EA',
  'es-EC',
  'es-ES',
  'es-GQ',
  'es-GT',
  'es-HN',
  'es-IC',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PH',
  'es-PR',
  'es-PY',
  'es-SV',
  'es-US',
  'es-UY',
  'es-VE',
  'et',
  'et-EE',
  'eu',
  'eu-ES',
  'fa',
  'fa-AF',
  'fa-IR',
  'ff',
  'ff-CM',
  'ff-GN',
  'ff-Latn',
  'ff-Latn-BF',
  'ff-Latn-CM',
  'ff-Latn-GH',
  'ff-Latn-GM',
  'ff-Latn-GN',
  'ff-Latn-GW',
  'ff-Latn-LR',
  'ff-Latn-MR',
  'ff-Latn-NE',
  'ff-Latn-NG',
  'ff-Latn-SL',
  'ff-Latn-SN',
  'ff-MR',
  'ff-SN',
  'fi',
  'fi-FI',
  'fo',
  'fo-DK',
  'fo-FO',
  'fr',
  'fr-BE',
  'fr-BF',
  'fr-BI',
  'fr-BJ',
  'fr-BL',
  'fr-CA',
  'fr-CD',
  'fr-CF',
  'fr-CG',
  'fr-CH',
  'fr-CI',
  'fr-CM',
  'fr-DJ',
  'fr-DZ',
  'fr-FR',
  'fr-GA',
  'fr-GF',
  'fr-GN',
  'fr-GP',
  'fr-GQ',
  'fr-HT',
  'fr-KM',
  'fr-LU',
  'fr-MA',
  'fr-MC',
  'fr-MF',
  'fr-MG',
  'fr-ML',
  'fr-MQ',
  'fr-MR',
  'fr-MU',
  'fr-NC',
  'fr-NE',
  'fr-PF',
  'fr-PM',
  'fr-RE',
  'fr-RW',
  'fr-SC',
  'fr-SN',
  'fr-SY',
  'fr-TD',
  'fr-TG',
  'fr-TN',
  'fr-VU',
  'fr-WF',
  'fr-YT',
  'fy',
  'fy-NL',
  'ga',
  'ga-IE',
  'gd',
  'gd-GB',
  'gl',
  'gl-ES',
  'gu',
  'gu-IN',
  'gv',
  'gv-IM',
  'ha',
  'ha-GH',
  'ha-Latn',
  'ha-Latn-GH',
  'ha-Latn-NE',
  'ha-Latn-NG',
  'ha-NE',
  'ha-NG',
  'he',
  'he-IL',
  'hi',
  'hi-IN',
  'hr',
  'hr-BA',
  'hr-HR',
  'hu',
  'hu-HU',
  'hy',
  'hy-AM',
  'ia',
  'id',
  'id-ID',
  'ig',
  'ig-NG',
  'ii',
  'ii-CN',
  'is',
  'is-IS',
  'it',
  'it-CH',
  'it-IT',
  'it-SM',
  'it-VA',
  'ja',
  'ja-JP',
  'jv',
  'jv-ID',
  'ka',
  'ka-GE',
  'ki',
  'ki-KE',
  'kk',
  'kk-Cyrl',
  'kk-Cyrl-KZ',
  'kk-KZ',
  'kl',
  'kl-GL',
  'km',
  'km-KH',
  'kn',
  'kn-IN',
  'ko',
  'ko-KP',
  'ko-KR',
  'ks',
  'ks-Arab',
  'ks-Arab-IN',
  'ks-IN',
  'ku',
  'ku-TR',
  'kw',
  'kw-GB',
  'ky',
  'ky-Cyrl',
  'ky-Cyrl-KG',
  'ky-KG',
  'lb',
  'lb-LU',
  'lg',
  'lg-UG',
  'ln',
  'ln-AO',
  'ln-CD',
  'ln-CF',
  'ln-CG',
  'lo',
  'lo-LA',
  'lt',
  'lt-LT',
  'lu',
  'lu-CD',
  'lv',
  'lv-LV',
  'mg',
  'mg-MG',
  'mi',
  'mi-NZ',
  'mk',
  'mk-MK',
  'ml',
  'ml-IN',
  'mn',
  'mn-Cyrl',
  'mn-Cyrl-MN',
  'mn-MN',
  'mr',
  'mr-IN',
  'ms',
  'ms-BN',
  'ms-Latn',
  'ms-Latn-BN',
  'ms-Latn-MY',
  'ms-Latn-SG',
  'ms-MY',
  'ms-SG',
  'mt',
  'mt-MT',
  'my',
  'my-MM',
  'nb',
  'nb-NO',
  'nb-SJ',
  'nd',
  'nd-ZW',
  'ne',
  'ne-IN',
  'ne-NP',
  'nl',
  'nl-AW',
  'nl-BE',
  'nl-BQ',
  'nl-CW',
  'nl-NL',
  'nl-SR',
  'nl-SX',
  'nn',
  'nn-NO',
  'no',
  'no-NO',
  'om',
  'om-ET',
  'om-KE',
  'or',
  'or-IN',
  'os',
  'os-GE',
  'os-RU',
  'pa',
  'pa-Arab',
  'pa-Arab-PK',
  'pa-Guru',
  'pa-Guru-IN',
  'pa-IN',
  'pa-PK',
  'pl',
  'pl-PL',
  'ps',
  'ps-AF',
  'ps-PK',
  'pt',
  'pt-AO',
  'pt-BR',
  'pt-CH',
  'pt-CV',
  'pt-GQ',
  'pt-GW',
  'pt-LU',
  'pt-MO',
  'pt-MZ',
  'pt-PT',
  'pt-ST',
  'pt-TL',
  'qu',
  'qu-BO',
  'qu-EC',
  'qu-PE',
  'rm',
  'rm-CH',
  'rn',
  'rn-BI',
  'ro',
  'ro-MD',
  'ro-RO',
  'ru',
  'ru-BY',
  'ru-KG',
  'ru-KZ',
  'ru-MD',
  'ru-RU',
  'ru-UA',
  'rw',
  'rw-RW',
  'sd',
  'sd-PK',
  'se',
  'se-FI',
  'se-NO',
  'se-SE',
  'sg',
  'sg-CF',
  'sh',
  'sh-BA',
  'si',
  'si-LK',
  'sk',
  'sk-SK',
  'sl',
  'sl-SI',
  'sn',
  'sn-ZW',
  'so',
  'so-DJ',
  'so-ET',
  'so-KE',
  'so-SO',
  'sq',
  'sq-AL',
  'sq-MK',
  'sq-XK',
  'sr',
  'sr-BA',
  'sr-Cyrl',
  'sr-Cyrl-BA',
  'sr-Cyrl-ME',
  'sr-Cyrl-RS',
  'sr-Cyrl-XK',
  'sr-Latn',
  'sr-Latn-BA',
  'sr-Latn-ME',
  'sr-Latn-RS',
  'sr-Latn-XK',
  'sr-ME',
  'sr-RS',
  'sr-XK',
  'sv',
  'sv-AX',
  'sv-FI',
  'sv-SE',
  'sw',
  'sw-CD',
  'sw-KE',
  'sw-TZ',
  'sw-UG',
  'ta',
  'ta-IN',
  'ta-LK',
  'ta-MY',
  'ta-SG',
  'te',
  'te-IN',
  'tg',
  'tg-TJ',
  'th',
  'th-TH',
  'ti',
  'ti-ER',
  'ti-ET',
  'tk',
  'tk-TM',
  'tl',
  'tl-PH',
  'to',
  'to-TO',
  'tr',
  'tr-CY',
  'tr-TR',
  'tt',
  'tt-RU',
  'ug',
  'ug-Arab',
  'ug-Arab-CN',
  'ug-CN',
  'uk',
  'uk-UA',
  'ur',
  'ur-IN',
  'ur-PK',
  'uz',
  'uz-AF',
  'uz-Arab',
  'uz-Arab-AF',
  'uz-Cyrl',
  'uz-Cyrl-UZ',
  'uz-Latn',
  'uz-Latn-UZ',
  'uz-UZ',
  'vi',
  'vi-VN',
  'wo',
  'wo-SN',
  'xh',
  'xh-ZA',
  'yi',
  'yo',
  'yo-BJ',
  'yo-NG',
  'zh',
  'zh-CN',
  'zh-HK',
  'zh-Hans',
  'zh-Hans-CN',
  'zh-Hans-HK',
  'zh-Hans-MO',
  'zh-Hans-SG',
  'zh-Hant',
  'zh-Hant-HK',
  'zh-Hant-MO',
  'zh-Hant-TW',
  'zh-MO',
  'zh-SG',
  'zh-TW',
  'zu',
  'zu-ZA',
];

export default countryCodes;
