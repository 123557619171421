<template>
  <dl class="properties-list">
    <slot />
  </dl>
</template>

<style>
  .properties-list .properties-item {
    margin-bottom: 8px;
  }
</style>
