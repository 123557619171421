<template>
  <div class="intro-message">
    <h2>Visualise everything in your &lt;head&gt; with Heads Up.</h2>
    <p>Enter your website in the top bar to begin.</p>
  </div>
</template>

<style>
  .intro-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
